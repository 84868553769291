<template>
  <div>
    <b-modal
      id="modal-confirm"
      centered
      title="Are you sure?"
      hide-footer
      @hidden="iConfirmDelete = false"
    >
      <p class="modal-text">
        In order to confirm that you wish to delete this data from the database,
        please enter its project name "<span class="name">{{ name }}</span
        >" in the input form and click <b>Delete</b>.
      </p>
      <input
        class="cus-input"
        v-model="listName"
        placeholder="Enter confirmation text"
      />
      <div class="modal-btn-box">
        <b-button class="cus-button cuz-button--white" @click="onCloseModal"
          >Cancel</b-button
        >
        <b-button
          @click="postDeleteList"
          :disabled="!listNameCheck"
          class="cus-button"
          >Delete</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { API_ENDPOINTS } from "../constants/common";
import { request } from "@/utils/request";

export default {
  name: "ConfirmationModal",
  data() {
    return {
      API_ENDPOINTS,
      listName: "",
      iConfirmDelete: false,
    };
  },
  props: ["confirmDelete", "postDeleteList", "name"],
  computed: {
    listNameCheck() {
      return this.listName === this.name;
    },
  },
  watch: {
    iConfirmDelete(event) {
      if (event) this.$bvModal.show("modal-confirm");
      if (!event) {
        this.$bvModal.hide("modal-confirm");
        this.iConfirmDelete = event;
        this.listName = "";
        this.$emit("onHandleDelete", { event });
      }
    },
    confirmDelete(event) {
      this.iConfirmDelete = event;
    },
  },
  methods: {
    onCloseModal($event) {
      this.$root.$emit("bv::hide::modal", "modal-confirm", $event.target);
      this.iConfirmDelete = false;
      this.listName = "";
    },
  },
};
</script>

<style lang="scss">
.name {
  color: #0071ce;
  font-weight: bold;
  font-style: italic;
}
.MarginTop {
  margin-top: 30px;
}
// .confirm-modal {
//   transform: translateY(-50%);
//   top: 50%;
// }
</style>
