<template>
  <div class="t-loader">
    <CCard>
      <div class="t-loader__row" v-for="r in 4">
        <vue-skeleton-loader
          v-for="i in 3"
          type="rect"
          rounded
          :width="'100%'"
          :height="'20px'"
          animation="fade"
        />
      </div>
    </CCard>
  </div>
</template>
<script>
export default {
  name: "BiSingleReportLoader",
};
</script>
<style lang="scss" scoped>
.t-loader {
  .card {
    padding: 15px;
    display: grid;
    gap: 20px;
  }
  &__row {
    display: flex;
    gap: 10px;
  }
}
</style>
