<template>
  <div>
    <TableLoader v-if="isDataLoading" />
    <div v-else class="roles-management">
      <div class="flex justify-end pr-30"></div>
      <table class="custom-table">
        <thead class="custom-table__head">
          <tr>
            <th v-for="f in fields" :key="f.key">
              {{ f.label ? f.label : f.key }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, i) in users" :key="d.email">
            <td class="custom-table__td">
              <CButton
                v-if="d.createNewUser"
                color="primary"
                variant="outline"
                class="flex align-center gap-2"
                v-c-tooltip="$t('Create new user')"
                @click="onCreateNew"
              >
                <CIcon name="cilPlus" />
              </CButton>
              <v-select
                v-else
                label="name"
                v-model="d.email"
                :options="options"
                style="min-width: 150px"
              />
            </td>
            <td class="custom-table__td">
              <div class="flex justify-center" v-if="!d.createNewUser">
                <b-form-checkbox
                  switch
                  v-model="d.anonymized_data"
                  size="lg"
                ></b-form-checkbox>
              </div>
            </td>
            <td class="custom-table__td">
              <div class="flex justify-center" v-if="!d.createNewUser">
                <b-form-checkbox
                  switch
                  v-model="d.upload_data"
                  size="lg"
                ></b-form-checkbox>
              </div>
            </td>
            <td class="custom-table__td">
              <div class="flex justify-center" v-if="!d.createNewUser">
                <b-form-checkbox
                  switch
                  v-model="d.real_data"
                  size="lg"
                ></b-form-checkbox>
              </div>
            </td>
            <td class="custom-table__td">
              <div class="flex justify-center" v-if="!d.createNewUser">
                <b-form-checkbox
                  switch
                  v-model="d.manage_data"
                  size="lg"
                ></b-form-checkbox>
              </div>
            </td>
            <td class="custom-table__td">
              <div class="flex justify-center" v-if="!d.createNewUser">
                <b-form-checkbox
                  switch
                  v-model="d.manage_access"
                  size="lg"
                ></b-form-checkbox>
              </div>
            </td>
            <td class="custom-table__td">
              <div class="flex justify-center">
                <CButton
                  v-if="!d.createNewUser"
                  color="primary"
                  variant="outline"
                  class="flex align-center gap-2"
                  v-c-tooltip="$t('Delete user access')"
                  @click="deleteUser(d)"
                >
                  <CIcon name="cilMinus" />
                </CButton>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex justify-end mt-5">
        <b-button
          color="primary"
          variant="outline"
          :disabled="
            isLoading ||
            (isAdministrator &&
              !(row?.access_info && row?.access_info?.manage_access))
          "
          class="btn cus-button mt-3 btn-loader__wrapper"
          :class="{ 'one-col': !isLoading }"
          @click="$emit('open-disclaimer')"
        >
          Save
          <CSpinner
            v-if="isLoading"
            color="white"
            style="width: 1.5rem; height: 1.5rem"
          />
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import CustomTable from "../../../components/CustomTable.vue";
import { users } from "../../../services/users";
import { TemplatesService } from "../../../services/templates";
import Loader from "../../../components/Loader.vue";
import { v4 as uuidv4 } from "uuid";
import "vue-select/dist/vue-select.css";
import { ActiveUser } from "@/services/user";
import { CModal } from "@mz/coreui-vue";
import TableLoader from "@/components/TableLoader.vue";

export default {
  name: "ModalRequest",
  components: { TableLoader, CustomTable, Loader },
  props: {
    row: Object,
  },
  data() {
    return {
      isAdministrator: this.$store.state.role.includes("superuser"),
      fields: [
        {
          key: "user",
          field: "user",
          label: "",
        },
        {
          key: "random_data",
          field: "random_data",
          label: "Randomized Data",
        },
        {
          key: "upload_data",
          field: "upload_data",
          label: "Upload Data",
        },
        {
          key: "real_data",
          label: "Access Real Data",
        },
        {
          key: "manage_data",
          label: "Manage Data",
        },
        {
          key: "manage_access",
          label: "Manage Access",
        },
        {
          key: "action",
          label: "Actions",
        },
      ],
      users: [],
      isLoading: false,
      newUser: {
        email: "",
        anonymized_data: false,
        real_data: false,
        manage_data: false,
        manage_access: false,
        upload_data: false,
      },
      isCreateNew: false,
      options: [],
      isDataLoading: false,
    };
  },
  mounted() {
    this.getFields();
    this.getAccessList();
  },
  methods: {
    onCreateNew() {
      this.users.splice(-1, 0, { ...this.newUser, uuid: uuidv4() });
    },
    async deleteUser(item) {
      if (item.uuid) {
        this.users = this.users.filter((i) => item.uuid !== i.uuid);
      }
    },
    async createUser(event) {
      const newUsers = this.users.slice(0, -1);
      if (newUsers.some((i) => !i.email)) {
        this.$toasted.show("Please, fill all email fields.", {
          position: "bottom-right",
          duration: 5000,
        });
        return;
      }
      this.isLoading = true;
      const body = {
        template: this.row.id,
        data: newUsers,
      };
      try {
        const { data } = await TemplatesService.grant(body);
        if (data.success) {
          this.getAccessList();
          this.$toasted.info("Successfully saved!", {
            position: "bottom-right",
            duration: 5000,
          });
          this.$root.$emit("bv::hide::modal", "modal-role-management");
          this.$emit("fetch");
        } else {
          let message = data.message;
          if (data.detail) {
            message = data.detail;
          }
          this.$toasted.error(message, {
            position: "bottom-right",
            duration: 5000,
          });
        }
      } catch (err) {
        console.log(err);
        this.$toasted.error(err, {
          position: "bottom-right",
          duration: 5000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async getFields() {
      this.isDataLoading = true;
      try {
        const { data } = await users.fetchField();
        this.options = data;
        this.isDataLoading = false;
      } catch (err) {
        console.log(err);
        this.isDataLoading = false;
      }
    },
    async getAccessList() {
      this.isDataLoading = true;
      try {
        const { data } = await TemplatesService.getAccessList(this.row.id);
        this.users = [
          ...data.data.map((i) => ({ ...i, uuid: uuidv4() })),
          {
            createNewUser: true,
          },
        ];
        this.isDataLoading = false;
      } catch (err) {
        console.log(err);
        this.isDataLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#modal-role-management .modal-dialog {
  max-width: 1200px;
  width: 100%;
}
</style>
