export const getNumericalValue = (value, round = 2) => {
  if (value === "0.0 %") {
    return "0 %";
  }
  if (typeof value === typeof 1) {
    if (value === 0) {
      return "-";
    }
    value = value.toFixed(round).replace(/\B(?=(\d{3})+(?!\d))/g, "\xa0");
  }
  if (value === "0") {
    return "-";
  }
  return value;
};

export const getSearchParam = (key = null) => {
  const params = new window.URLSearchParams(window.location.search);
  if (!key) return params;
  return params.get(key) || null;
};

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
};
