<template>
  <b-modal id="modal-request" centered title="Request" hide-footer>
    <form>
      <label class="cus-label">
        <span class="cus-label__title">Request</span>
        <textarea
          v-model="comment"
          class="cus-input"
          rows="4"
          placeholder="Enter your request"
        />
      </label>

      <div class="modal-btn-box">
        <b-button
          class="cus-button cuz-button--white"
          @click="
            $root.$emit('bv::hide::modal', 'modal-request', $event.target)
          "
          >Cancel</b-button
        >
        <b-button @click="onRequest" class="cus-button">Send</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import { AnonymizeService } from "../../../services/anonymize";

export default {
  name: "ModalRequest",
  props: {
    row: Object,
  },
  data() {
    return {
      comment: "",
    };
  },
  methods: {
    async onRequest($event) {
      const { data } = await AnonymizeService.request({
        project_name: this.row.project_name,
        client_name: this.row.client_name,
        comment: this.comment,
      });
      if (!data.success) alert(data.error);
      else {
        try {
          await navigator.clipboard.writeText(data.conn);
          this.$toasted.show("Connection string copied!", {
            position: "bottom-right",
            duration: 5000,
          });
        } catch (e) {
          console.log(e);
        }
      }
      this.$root.$emit("bv::hide::modal", "modal-request", $event.target);
    },
  },
};
</script>
