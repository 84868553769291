<template>
  <div class="table-responsive">
    <table class="custom-table">
      <thead class="custom-table__head">
        <tr>
          <th v-for="(f, i) in filteredFileds" :key="f.key">
            <slot :name="f.key + '_th'" :data="{ field: f, index: i }">
              <div class="custom-table__head__th d-flex gap-2 align-center">
                <span>{{ f.label ? f.label : f.key }}</span>
                <button class="sort-btn" v-if="f.sortable" @click="onSort(f)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-down-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
                    />
                  </svg>
                </button>
              </div>
            </slot>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="filteredFileds.some((item) => item.sortable)">
          <td
            class="custom-table__filter-td"
            v-for="f in filteredFileds"
            :key="f.key"
          >
            <div
              v-if="f.sortable && !['custom'].includes(f.key)"
              class="custom-table__filter"
            >
              <CIcon name="search" size="custom" />
              <input
                :placeholder="`Filter by ${f.key}`"
                @input="searchChange($event, f.field)"
              />
            </div>
          </td>
        </tr>
        <tr v-for="(d, ind) in filteredData" :key="getUniqueId()">
          <td class="custom-table__td" v-for="f in filteredFileds" :key="f.key">
            <slot :name="`${f.key}`" :data="{ ...d, index: ind }"> </slot>
          </td>
        </tr>
      </tbody>
    </table>

    <slot name="tableBottom"> </slot>

    <div class="custom-table__footer">
      <div>
        <b-dropdown
          v-if="showFilterColumns"
          class="columns-dropdown"
          boundary="window"
          ref="ddown"
        >
          <template #button-content>
            Columns
            <!-- <CIcon name="carret_down" size="custom" /> -->
          </template>
          <b-dropdown-group>
            <b-button class="btn-p" @click="onUnselect">Unselect all</b-button>
            <b-button class="btn-p" @click="onReset"
              >Reset columns to default</b-button
            >
          </b-dropdown-group>
          <b-dropdown-text>First level</b-dropdown-text>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-group>
            <div class="dropdown-checkboxes">
              <b-form-checkbox
                v-for="f in fields"
                :key="f.key"
                :id="f.key"
                :name="f.key"
                v-model="fieldValues[f.key]"
                :value="true"
                :unchecked-value="false"
              >
                <span class="checkbox-text">{{ f.key }}</span>
              </b-form-checkbox>
            </div>
          </b-dropdown-group>
        </b-dropdown>
      </div>

      <div class="custom-table__pagination-wrapper">
        <span class="custom-table__text">Results per page</span>
        <b-dropdown
          id="dropdown-1"
          :text="perPage.toString()"
          class="m-md-2 custom-dropdown"
        >
          <b-dropdown-item
            @click="onPerPageChange(i)"
            v-for="i in perPageItems"
            :key="i"
            :active="i === perPage"
            >{{ i }}</b-dropdown-item
          >
        </b-dropdown>
        <span class="custom-table__text">of {{ totalRows }}</span>

        <div class="custom-table__pagination" v-if="perPage !== 'All'">
          <b-pagination
            :value="page"
            @input="onPageChange"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { debounce } from "@/utils/helpers";

const sortValues = { asc: "asc", desc: "desc", none: "none" };
export default {
  name: "CustomTable",
  props: {
    fields: Array,
    values: Array,
    customPagination: Boolean,
    paginationData: Object,
    showFilterColumns: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      perPageItems: [10, 50, 100],
      fieldValues: {},
      searchValues: {},
      sort: { field: "", value: sortValues.none },
      sortValues,
      searchChange: null,
    };
  },
  watch: {
    paginationData: {
      immediate: true,
      deep: true,
      handler() {
        if (this.customPagination) {
          this.page = this.paginationData.page;
          this.perPage = this.paginationData.page_size;
        }
      },
    },
  },
  computed: {
    totalRows() {
      return this.customPagination
        ? this.paginationData.total
        : this.dataAfterSearch.length;
    },
    dataAfterSearch() {
      let data = [...this.values];

      if (this.sort.field && this.sort.value !== this.sortValues.none) {
        data = data.sort((a, b) => {
          let stringCompare = (s1, s2) => s1.localeCompare(s2);
          const numberCompare = (n1, n2) => n1 - n2;
          const dateCompare = (d1, d2) => {
            const date1 = new Date(d1);
            const date2 = new Date(d2);
            return date1.getTime() - date2.getTime();
          };

          const comparer = (first, second) => {
            switch (this.sort.type) {
              case "number":
                return numberCompare(first, second);
              case "date":
                return dateCompare(first, second);
              default:
                return stringCompare(
                  first ? first.toUpperCase() : "",
                  second ? second.toUpperCase() : ""
                );
            }
          };

          if (this.sort.value === this.sortValues.asc) {
            return comparer(a[this.sort.field], b[this.sort.field]);
          } else if (this.sort.value === this.sortValues.desc) {
            return comparer(b[this.sort.field], a[this.sort.field]);
          }
        });
      }

      if (Object.keys(this.searchValues).length > 0) {
        data = data.filter((item) => {
          return this.fields.every((field) => {
            const searchValue = this.searchValues[field?.field];
            if (searchValue) {
              return item[field?.field]
                ?.toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase());
            }
            return true;
          });
        });
      }

      return data;
    },
    filteredData() {
      if (this.customPagination) {
        return this.values;
      } else if (this.perPage === "All") {
        return this.dataAfterSearch;
      } else {
        return this.dataAfterSearch.slice(
          (this.page - 1) * this.perPage,
          this.perPage * this.page
        );
      }
    },
    filteredFileds() {
      return this.fields.filter((index) => this.fieldValues[index.key]);
    },
  },
  created() {
    this.searchChange = debounce((e, key) => {
      if (
        (e.target.value && e.target.value.length >= 3) ||
        (e.target.value === "" && this.searchValues[key]?.length)
      ) {
        this.searchValues = { ...this.searchValues, [key]: e.target.value };
        this.$emit("onSearch", this.searchValues);
      }
    }, 500);
  },
  mounted() {
    this.setFieldValues(true);
  },
  methods: {
    onPageChange(p) {
      if (this.customPagination) {
        this.$emit("update:page", p);
      } else this.page = p;
    },
    onPerPageChange(p) {
      if (this.customPagination) {
        this.$emit("update:perPage", p);
      } else this.perPage = p;
    },
    onSort(f) {
      let value = this.sortValues.none;
      if (this.sort.field === f.field) {
        if (this.sort.value === this.sortValues.asc) {
          value = this.sortValues.desc;
        } else if (this.sort.value === this.sortValues.desc) {
          value = this.sortValues.none;
        } else {
          value = this.sortValues.asc;
        }
      } else {
        value = this.sortValues.asc;
      }
      this.sort = { field: f.field, value, type: f.sortType };
    },
    setFieldValues(val) {
      const v = {};
      this.fields.forEach((index) => {
        v[index.key] = val;
      });
      this.fieldValues = v;
    },
    onUnselect() {
      this.setFieldValues(false);
      this.$refs.ddown.hide();
    },
    onReset() {
      this.setFieldValues(true);
      this.$refs.ddown.hide();
    },
    getUniqueId() {
      return Date.now().toString(36) + Math.random().toString(36).substring(2);
    },
  },
};
</script>
<style lang="scss" scoped>
.sort-btn {
  border: none;
  background-color: inherit;
  display: grid;
  color: #ffffff;
  outline: none;

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }

  &__icon {
    color: #fff;
  }
}
.checkbox-text {
  text-transform: capitalize;
}
:deep(.columns-dropdown) {
  .dropdown-menu {
    width: 350px;
  }
  .btn-p {
    color: #0071ce;
  }
  .b-dropdown-text {
    margin: 10px 15px;
    color: #0071ce;
    font-weight: 700;
  }
  .btn-secondary {
    background: inherit;
    border: 0;
    outline: none;

    &:active {
      background: inherit;
      border: 0;
      outline: none;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
.dropdown-checkboxes {
  margin: 10px 15px;
}
</style>
