<template>
  <b-modal
    id="modal-anonymize"
    centered
    :title="row.anonymized ? 'Rerandomize' : 'Randomize'"
    hide-footer
  >
    <p class="modal-text">
      Are you seeking to randomize your data? With data randomization, you can
      protect sensitive information and maintain privacy. By removing personally
      identifiable details, you can ensure that the data remains anonymous while
      still retaining its value for analysis and research purposes.
    </p>
    <div class="modal-btn-box">
      <b-button
        class="cus-button cuz-button--white"
        @click="
          $root.$emit('bv::hide::modal', 'modal-anonymize', $event.target)
        "
        >Cancel</b-button
      >
      <b-button class="cus-button" @click="onAnonymize">{{
        row.anonymized ? "Rerandomize" : "Randomize"
      }}</b-button>
    </div>

    <Loader v-if="isLoading" />
  </b-modal>
</template>
<script>
import { AnonymizeService } from "@/services/anonymize";
import Loader from "../../../components/Loader.vue";
export default {
  name: "ModalAnonymize",
  props: {
    row: Object,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async onAnonymize($event) {
      this.isLoading = true;
      this.$toasted.info(
        "Data is being randomized, it can take up to 10 minutes.",
        {
          position: "bottom-right",
          duration: 5000,
        }
      );
      this.$root.$emit("bv::hide::modal", "modal-anonymize", $event.target);
      try {
        const body = {
          template: this.row,
        };
        if (this.row.anonymized) {
          body.reanon = true;
        }
        const { data } = await AnonymizeService.process(body);
        // if (!data.success) alert(data.error);
        // else {
        //   navigator.clipboard.writeText(data.conn).then((res) => {
        //     this.$toasted.info("Connection string copied!", {
        //       position: "top-right",
        //       duration: 5000,
        //     });
        //   });
        // }
        this.isLoading = false;
        this.$emit("fetch");
      } catch (e) {
        this.isLoading = false;
        console.log(e);
      }
    },
  },
  components: { Loader },
};
</script>
