<template>
  <div class="table-responsive">
    <TableLoader v-if="isDataLoading" />
    <CustomTable
      :fields="fields"
      :values="templates || []"
      customPagination
      :paginationData="{ page: page, total: total, page_size: limit }"
      @update:page="(p) => onChangePage(p, limit)"
      @update:perPage="(l) => onChangePage(1, l)"
      @onSearch="onSearch"
      v-else
    >
      <template #client="{ data }">
        {{ data.client_name }}
      </template>
      <template #project="{ data }">
        {{ data.project_name }}
      </template>
      <template #custom="{ data }">
        {{ data.custom_columns }}
      </template>
      <template #anonymized="{ data }">
        <div class="buttons-anonymize">
          <b-button
            v-b-modal.modal-anonymize
            @click="onAnonymize(data)"
            class="light-btn"
            :disabled="
              !(data.access_info && data.access_info.anonymized_data) ||
              data.isAnonymizing
            "
          >
            <CIcon name="anonymize" size="custom" />
            {{
              data.anonymized
                ? "Rerandomize"
                : data.isAnonymizing
                ? "Randomizing..."
                : "Randomize"
            }}
          </b-button>
          <b-button
            :disabled="
              !(data.access_info && data.access_info.anonymized_data) ||
              !data.anonymized
            "
            @click="onCopy(false, data.client_name, data.project_name)"
            class="light-btn"
          >
            Copy connection string
          </b-button>
        </div>
      </template>
      <template #original="{ data }">
        <b-button
          :disabled="!(data.access_info && data.access_info.real_data)"
          @click="onCopy(true, data.client_name, data.project_name)"
          class="light-btn"
        >
          Copy connection string
        </b-button>
      </template>
      <template #actions="{ data }">
        <div
          class="d-flex gap-1 flex-center"
          v-c-tooltip="
            data.is_updating
              ? {
                  content: 'Template is updating',
                  placement: 'top',
                }
              : null
          "
        >
          <div
            v-c-tooltip="{
              content: data.connected_to_BI
                ? 'Table is connected to BI template and cannot be changed'
                : $t('Roles management'),
            }"
          >
            <CButton
              :disabled="
                ((!(data.access_info && data.access_info.manage_access) ||
                  data.is_updating) &&
                  !isAdministrator) ||
                data.connected_to_BI
              "
              @click="onRoleManagement(data)"
              v-b-modal.modal-role-management
              color="primary"
              class="action-btn"
              variant="outline"
            >
              <CIcon name="cilPeople" />
            </CButton>
          </div>

          <div
            v-c-tooltip="{
              content: data.connected_to_BI
                ? 'Table is connected to BI template and cannot be changed'
                : $t('Edit data'),
            }"
          >
            <CButton
              @click="() => editRow(data)"
              color="primary"
              class="action-btn"
              :disabled="
                ((!(data.access_info && data.access_info.manage_data) ||
                  data.is_updating) &&
                  !isAdministrator) ||
                data.connected_to_BI
              "
              variant="outline"
            >
              <CIcon class="icon-size" name="pencil" size="custom" />
            </CButton>
          </div>
          <div
            v-c-tooltip="{
              content: data.connected_to_BI
                ? 'Table is connected to BI template and cannot be changed'
                : $t('Delete data'),
            }"
          >
            <CButton
              :disabled="
                !(data.access_info && data.access_info.manage_data) ||
                data.is_updating ||
                data.connected_to_BI
              "
              @click="
                () => {
                  handleConfirmDelete(data);
                  deletable_row = data;
                }
              "
              color="danger"
              class="action-btn"
              variant="outline"
            >
              <CIcon class="icon-size" name="trash" size="custom" />
            </CButton>
          </div>
        </div>
      </template>
    </CustomTable>

    <ModalRequest :row="currentRow" />
    <ModalAnonymize @fetch="getTemplates" :row="currentRow" />
    <CModal
      :show.sync="showRoleManagement"
      :closeOnBackdrop="false"
      @blur="showRoleManagement = false"
      @close="showRoleManagement = false"
      @show="showRoleManagement = false"
      width="80%"
      alignment="center"
      class="no-modal-footer"
    >
      <template slot="header">
        <h4>Roles Management</h4>
        <svg
          @click="showRoleManagement = false"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-x"
          viewBox="0 0 16 16"
        >
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </template>
      <ModalRoleManagement
        ref="roleManagementRef"
        v-if="showRoleManagement"
        @fetch="getTemplates"
        :row="currentRow"
        @open-disclaimer="openDisclaimer"
      />
      <template slot="footer">{{ null }}</template>
    </CModal>

    <CModal
      :show.sync="editVisible"
      :closeOnBackdrop="false"
      @blur="closeModal"
      @close="closeModal"
      @show="closeModal"
      width="80%"
      height="90%"
      alignment="center"
    >
      <template slot="header">
        <h4>
          {{
            `Edit data: ${editable_row ? editable_row.client_name : ""} - ${
              editable_row ? editable_row.project_name : ""
            }`
          }}
        </h4>
        <svg
          @click="closeModal"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-x"
          viewBox="0 0 16 16"
        >
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </template>
      <div v-if="custom_columns && custom_columns.length">
        <h3>Custom columns</h3>
        <div class="table-responsive">
          <table class="table table-sm">
            <thead class="header">
              <th v-for="h in ['Name', 'Last value']">{{ h }}</th>
            </thead>
            <tbody>
              <tr v-for="(column, index) in custom_columns">
                <td class="align-middle">
                  <CInput
                    :value.sync="column[0]"
                    :class="{ error: checkSpecialChars(column[0]) }"
                    :placeholder="'Name'"
                  />
                </td>
                <td class="align-middle">
                  <CInput :value.sync="column[1]" :disabled="true" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="CheckSwitch">
        <input
          type="checkbox"
          v-model="all_checked"
          @change="onAllCheckChange"
          color="primary"
        />
        <span class="CheckText">Check all</span>
      </div>
      <table class="table">
        <thead class="header">
          <th
            v-for="h in [
              'Include',
              'Column in the file',
              'Column in the database',
              'Type',
              'Unique',
              'Anonymize',
            ]"
          >
            {{ h }}
          </th>
          <!-- <th v-if="showFormat">Format</th> -->
          <th>Comment</th>
          <!-- <th v-if="showComments">Comment</th> -->
        </thead>
        <tbody>
          <tr v-for="column in data_for_edit">
            <td class="align-middle text-center">
              <input type="checkbox" id="checkbox" v-model="column.include" />
            </td>
            <td class="align-middle">
              <vue-tags-input
                v-model="column.newFname"
                :tags="column.fnames"
                @tags-changed="
                  (newTags) => (column.fnames = newTags.map((a) => a.text))
                "
                :placeholder="'Add name'"
              />
            </td>
            <td class="align-middle">
              <CInput
                :value.sync="column.renamed"
                :class="{ 'invalid-input': !validate(column.renamed) }"
              />
              <span class="input-subtext" v-if="!validate(column.renamed)">
                Special symbols are not available</span
              >
            </td>
            <td>
              <CSelect
                class="Dtype"
                :value.sync="column.dtype"
                :options="[
                  'Numeric',
                  'String',
                  'Date',
                  'Boolean',
                  'Country',
                  'City',
                ]"
              ></CSelect>
            </td>
            <td class="align-middle text-center">
              <input type="checkbox" id="checkbox" v-model="column.unique" />
            </td>
            <td class="align-middle text-center">
              <input
                type="checkbox"
                id="checkbox"
                v-model="column.anonimized"
              />
            </td>
            <!-- <td class="align-middle" v-if="column.dtype=='Date'">
              <CSelect class="Dropdown" :value.sync="column.date_format" :options="['[D][M][Y]', '[M][D][Y]', '[Y][D][M]', '[Y][M][D]']"/>
            </td> -->
            <!-- <td class="align-middle" v-if="showFormat && column.dtype!='Date'"></td> -->
            <td class="align-middle InputCol">
              <CInput :value.sync="column.comment" class="Inputs"></CInput>
            </td>
            <!-- <td class="align-middle Comment" v-if="showComments && comments[column[1]]">
                      {{comments[column[1]]}}
                    </td>
                    <td class="align-middle" v-if="!comments[column[1]] && showComments"></td> -->
          </tr>
        </tbody>
      </table>
      <CAlert v-if="notUniqueNames" color="warning"
        >Column names are not unique!</CAlert
      >
      <CAlert v-if="emptyNames" color="warning"
        >Column names shouldn't be empty!</CAlert
      >
      <CAlert v-if="containSpecialChars" color="warning"
        >Column names shouldn't contain special characters or spaces!</CAlert
      >
      <template slot="footer">
        <b-button
          class="btn mt-3 cus-button cuz-button--white"
          @click="closeModal"
        >
          Cancel
        </b-button>
        <b-button
          class="btn cus-button mt-3"
          @click="openConfirmModal"
          :disabled="
            Boolean(
              notUniqueNames ||
                emptyNames ||
                containSpecialChars ||
                invalidColumnName
            ) ||
            (isAdministrator &&
              !(
                editable_row?.access_info &&
                editable_row?.access_info?.manage_data
              ))
          "
        >
          Update
        </b-button>
      </template>

      <CModal
        :show.sync="confirmEditVisible"
        :closeOnBackdrop="false"
        @blur="closeConfirmModal"
        @close="closeConfirmModal"
        @show="closeConfirmModal"
        width="40%"
        height="45%"
        alignment="center"
      >
        <template slot="header">
          <h3>Do you want to change following columns?</h3>
          <svg
            @click="closeConfirmModal"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            class="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </template>
        <h4>This will have impact on existing data.</h4>
        <template slot="footer">
          <button
            class="btn mt-3 cus-button cuz-button--white"
            @click="closeConfirmModal"
          >
            Cancel
          </button>
          <!-- <button class="btn cus-button mt-3" @click="editData()">
            Accept
          </button> -->
          <b-button
            class="btn cus-button mt-3"
            @click="editData"
            :disabled="isLoading"
          >
            <div class="btn-loader__wrapper" :class="{ 'one-col': !isLoading }">
              <span>Save</span>
              <CSpinner
                v-if="isLoading"
                color="white"
                style="width: 1.5rem; height: 1.5rem"
              />
            </div>
          </b-button>
        </template>
      </CModal>
    </CModal>
    <ConfirmationModal
      :confirmDelete="confirmDelete"
      :name="this.currentRow.project_name"
      :postDeleteList="deleteData"
      @onHandleDelete="onHandleDelete"
    />
    <CModal
      :show.sync="isShowDisclaimer"
      :closeOnBackdrop="false"
      @blur="isShowDisclaimer = false"
      @close="isShowDisclaimer = false"
      @show="isShowDisclaimer = false"
      width="50%"
      alignment="center"
    >
      <template slot="header"><h4>Disclaimer</h4></template>
      <template>
        <div>
          <h5>Upload data:</h5>
          <p>
            {{
              `
          You confirm that you informed Users about what types of data can and cannot be uploaded to DataMorph using
           the template ${
             this.currentRow.client_name + " - " + this.currentRow.project_name
           }.Uploaded data will be transferred and stored in Azure cloud database
          located in East US and managed by Forvis Mazars Consulting LLC(Georgia). Users should ensure that no client
           agreements, NDAs, local or global regulations related to data storage, transfer or processing are violated.`
            }}
          </p>
          <h5>Access Real Data:</h5>
          <p>
            {{
              `You confirm that you informed Users about restrictions and allowed / not allowed purposes of using data
          stored in the template ${
            this.currentRow.client_name + " - " + this.currentRow.project_name
          }.`
            }}
          </p>
          <h5>Manage Access:</h5>
          <p>
            {{
              `You confirm that you informed Users about ${
                this.currentRow.client_name +
                " - " +
                this.currentRow.project_name
              } dataset co-owner status and responsibilities for its data compliance. Uploaded data will be transferred and stored in Azure cloud database located in East US and managed by Forvis Mazars Consulting LLC (Georgia). Users should ensure that no client agreements, NDAs, local or global regulations related to data storage, transfer or processing are violated.`
            }}
          </p>
        </div>
      </template>
      <template slot="footer">
        <div class="d-flex gap-3">
          <b-button
            class="btn cus-button cuz-button--white"
            @click="isShowDisclaimer = false"
          >
            Cancel
          </b-button>
          <b-button class="btn cus-button" @click="closeDisclaimerModal">
            <span>Confirm</span>
          </b-button>
        </div>
      </template>
    </CModal>
    <CModal
      :show.sync="isShowDisclaimerFirst"
      :closeOnBackdrop="false"
      @blur="isShowDisclaimerFirst = false"
      @close="isShowDisclaimerFirst = false"
      @show="isShowDisclaimerFirst = false"
      width="50%"
      alignment="center"
    >
      <template slot="header">
        <h4>Disclaimer</h4>
      </template>
      <template>
        <p>
          {{
            `
            As a dataset (co-)owner, you are responsible for explaining data compliance requirements related to the dataset
            ` +
            this.currentRow.client_name +
            " - " +
            this.currentRow.project_name +
            ` to all users, to whom you will grant access to.
            `
          }}
        </p>
      </template>
      <template slot="footer">
        <b-button class="btn cus-button" @click="onClickConfirmDisclaimer">
          <span>Confirm</span>
        </b-button>
      </template>
    </CModal>
  </div>
</template>

<script>
import { ListsService } from "@/services/lists";
import { TemplatesService } from "@/services/templates";
import { AnonymizeService } from "@/services/anonymize";
import InvoiceTable from "../../components/InvoiceTable.vue";
import { CModal } from "@mz/coreui-vue";
import VueTagsInput from "@johmun/vue-tags-input";
import ConfirmationModal from "../../components/ConfirmationModal.vue";
import CustomTable from "../../components/CustomTable.vue";
import ModalRequest from "./modals/ModalRequest.vue";
import ModalAnonymize from "./modals/ModalAnonymize.vue";
import ModalRoleManagement from "./modals/ModalRoleManagement.vue";
import { ActiveUser } from "@/services/user";
import TableLoader from "@/components/TableLoader.vue";

export default {
  name: "History",
  components: {
    TableLoader,
    InvoiceTable,
    CModal,
    ConfirmationModal,
    VueTagsInput,
    CustomTable,
    ModalRequest,
    ModalAnonymize,
    ModalRoleManagement,
  },
  data() {
    return {
      isShowDisclaimerFirst: false,
      templates: null,
      isAdministrator: this.$store.state.role.includes("superuser"),
      editVisible: false,
      confirmEditVisible: false,
      editable_row: null,
      data_for_edit: [],
      custom_columns: [],
      confirmDelete: false,
      showRoleManagement: false,
      currentRow: {},
      deletable_row: null,
      all_checked: false,
      prev_ver_templ: null,
      pkl_for_edit: null,
      inputtedFnames: {},
      page: 1,
      limit: 10,
      total: 0,
      fields: [
        { key: "client", sortable: true, field: "client_name" },
        { key: "project", sortable: true, field: "project_name" },
        {
          key: "custom",
          sortable: true,
          field: "custom_columns",
          label: "custom columns",
        },
        { key: "anonymized", label: "Randomized Data" },
        { key: "original", label: "Original data" },
        { key: "actions" },
      ],
      isLoading: false,
      isShowDisclaimer: false,
      editRowData: null,
      isDataLoading: true,
      filter: {},
    };
  },

  computed: {
    showFormat() {
      if (this.editVisible) {
        return this.data_for_edit.map((a) => a.dtype).includes("Date");
      }
    },
    containSpecialChars() {
      return this.custom_columns.some((i) => this.checkSpecialChars(i[0]));
    },
    notUniqueNames() {
      let unique_cust = this.custom_columns
        .map((a) => a[0].toLowerCase())
        .filter(
          (value, index, array) =>
            array.indexOf(value) === index && value !== ""
        );
      let unique_renamed = this.data_for_edit
        .map((a) => a.renamed?.toLowerCase())
        .filter((value, index, array) => array.indexOf(value) === index);
      let all_not_unique_names = this.custom_columns
        .map((a) => a[0].toLowerCase())
        .filter((value) =>
          this.data_for_edit
            .map((a) => a.renamed?.toLowerCase())
            .includes(value)
        );
      return (
        this.custom_columns.filter((a) => a[0] != "").length !=
          unique_cust.length ||
        this.data_for_edit.length != unique_renamed.length ||
        all_not_unique_names.length
      );
    },
    emptyNames() {
      return Boolean(
        this.custom_columns.filter((a) => a[0] == "" && a[1] != "").length ||
          this.data_for_edit.filter((a) => a.renamed == "").length
      );
    },
    invalidColumnName() {
      return this.data_for_edit.some((i) => !this.validate(i.renamed));
    },
  },

  methods: {
    onSearch(s) {
      this.filter = {
        client: s.client_name || "",
        project: s.project_name || "",
      };
      this.getTemplates();
    },
    onChangePage(p, limit) {
      this.page = p;
      this.limit = limit;
      this.isDataLoading = true;
      this.getTemplates();
    },
    validate(column_name) {
      const pattern = /^[a-zA-Z0-9_() ]+$/;
      return pattern.test(column_name);
    },
    closeDisclaimerModal() {
      this.$refs.roleManagementRef.createUser();
      this.isShowDisclaimer = false;
    },
    async onCopy(val = false, client_name, project_name) {
      try {
        const { data } = await AnonymizeService.copy(
          val,
          client_name,
          project_name
        );
        await navigator.clipboard.writeText(data.conn);
        this.$toasted.info("Connection string copied!", {
          position: "top-right",
          duration: 5000,
        });
      } catch (e) {
        console.log(e);
      }
    },
    onAllCheckChange({ target }) {
      this.data_for_edit.map((a) => (a.include = target.checked));
    },
    checkSpecialChars(item) {
      return item && !/^[a-zA-Z0-9]([a-zA-Z0-9_-]*[a-zA-Z0-9])?$/.test(item);
    },

    async onAnonymize(data) {
      this.$bvModal.show("modal-anonymize");
      this.currentRow = data;
    },
    onRequest(data) {
      this.currentRow = data;
    },
    openDisclaimer() {
      this.isShowDisclaimer = true;
    },
    onRoleManagement(data) {
      this.isShowDisclaimerFirst = true;
      this.currentRow = data;
    },
    onClickConfirmDisclaimer() {
      this.isShowDisclaimerFirst = false;
      this.showRoleManagement = true;
    },
    async getTemplates() {
      this.isLoading = true;
      var { data, res } = await ListsService.get_list_template({
        page: this.page,
        limit: this.limit,
        filter: this.filter,
      });
      if (res.status !== 200) {
        this.isDataLoading = false;
        return alert("Please check your internet connection or log in");
      }
      if (data.success) {
        this.isDataLoading = false;
        this.templates = data.data.reverse();
        this.templates = this.templates.map((a) => {
          a.custom_columns = a.custom_columns.map((b) => b[0]).join(", ");
          return a;
        });
        this.total = data.total;
      }
      if (this.isLoading) {
        this.isLoading = false;
      }
    },

    async editRow(row) {
      if (row) {
        var { data, res } = await TemplatesService.find({
          client_id: row.client,
          project_id: row.project,
        });
        if (res.status !== 200) {
          return alert("Please check your internet connetion or log in");
        }
        if (data.success) {
          this.data_for_edit = data.templates[0].columns.map((a) => {
            return { ...a, newFname: "" };
          });
          this.custom_columns = data.templates[0].custom_columns;
          this.prev_ver_templ = JSON.parse(JSON.stringify(data.templates[0]));
          this.pkl_for_edit = data.templates[0].pkl_id;
          this.editable_row = row;
          this.all_checked = this.data_for_edit.every((val) => val.include);
          this.editVisible = true;
        }
      }
    },

    async editData() {
      this.isLoading = true;
      var { data, res } = await TemplatesService.edit_data({
        templateData: this.data_for_edit.map(
          ({ newFname, ...keepAttrs }) => keepAttrs
        ),
        id: this.editable_row.id,
        client: this.editable_row.client_name,
        project: this.editable_row.project_name,
        before_edited: this.prev_ver_templ.columns,
        pkl_for_edit: this.pkl_for_edit,
        custom_columns: this.custom_columns,
        custom_columns_before: this.prev_ver_templ.custom_columns,
      });
      this.$toasted.show("Your changes were sent to server!", {
        position: "bottom-right",
        duration: 5000,
      });
      this.isLoading = false;
      this.closeModal();
      this.closeConfirmModal();
      this.getTemplates();
      this.$forceUpdate();
    },

    async deleteData() {
      this.confirmDelete = false;
      console.log(this.deletable_row);
      this.templates = this.templates.filter(
        (a) =>
          !(
            a.client == this.deletable_row.client &&
            a.project == this.deletable_row.project &&
            a.upload_id == this.deletable_row.upload_id
          )
      );

      const { data, res } = await TemplatesService.delete_template({
        pickle_id: this.deletable_row.pkl_id,
        client_name: this.deletable_row.client_name,
        project_name: this.deletable_row.project_name,
      }).then(() => {
        this.confirmDelete = false;
      });

      if (res.status !== 200) {
        return alert("Please check your internet connetion or log in");
      }
    },

    async handleConfirmDelete(row) {
      this.currentRow = row;
      this.confirmDelete = true;
    },
    async onHandleDelete({ event }) {
      this.confirmDelete = event;
    },

    closeModal() {
      this.editVisible = false;
    },

    openConfirmModal() {
      this.confirmEditVisible = true;
    },
    closeConfirmModal() {
      this.confirmEditVisible = false;
    },
  },
  created() {
    this.getTemplates();
    window.addEventListener("beforeunload", clearInterval(this.timer));
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    this.timer = setInterval(() => {
      if (
        !this.isLoading &&
        !this.showRoleManagement &&
        !this.editVisible &&
        !this.confirmDelete &&
        !this.isShowDisclaimer &&
        !this.isShowDisclaimerFirst
      )
        this.getTemplates();
    }, 10000);
  },
};
</script>

<style scoped lang="scss">
.error {
  input {
    border-color: #dc3545 !important;
  }
}
.buttons-anonymize {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media only screen and (max-width: 1422px) {
  .buttons-anonymize {
    display: flex;
    flex-direction: column;
    //align-items: center;
    gap: 10px;
    padding: 5px;
  }
}

.light-btn {
  height: 36px;
  background: #e7edfb;
  border-radius: 10px;
  padding: 10px 23px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  .c-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  color: #3e6bbd;
}
.StatusUploaded {
  color: green;
}
.StatusFailed {
  color: red;
}
.StatusInProgress {
  color: gold;
}
.action-btn.btn-outline-primary:hover {
  color: #0071ce;
}
.header th {
  white-space: nowrap;
}
</style>
